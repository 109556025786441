// @ts-ignore
import { init } from '@jill64/sentry-sveltekit-cloudflare/client'
import { PUBLIC_CART_SERVICE_URL, PUBLIC_GENERAL_SERVICE_URL } from '$env/static/public';
import { generateSignature } from '$lib/utils/Signature';
import type { HandleFetch } from '@sveltejs/kit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

export const handleFetch: HandleFetch = async ({ request, fetch }) => {
	if (request.url.startsWith(PUBLIC_GENERAL_SERVICE_URL)) {
		const signature = await generateSignature();
		request.headers.set('X-SA-Key', signature.get('X-SA-Key')!);
		request.headers.set('X-SA-Date', signature.get('X-SA-Date')!);

	}
	if (request.url.startsWith(PUBLIC_CART_SERVICE_URL)) {
		const signature = await generateSignature();
		request.headers.set('X-SA-Key', signature.get('X-SA-Key')!);
		request.headers.set('X-SA-Date', signature.get('X-SA-Date')!);
	}
	return fetch(request);
};

const onError = init(
	'https://89b51c295972d2387f45f8772cb51340@o4507605598535680.ingest.de.sentry.io/4507464397226064',
	{
	  sentryOptions: { 
			ignoreErrors: ["ResizeObserver loop limit exceeded"],
	   },
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	 }
)

export const handleError = onError((e: any, sentryEventId: any) => {
	console.error('Error:', e, sentryEventId);
	handleErrorWithSentry();
})